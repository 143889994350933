import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { getLink } from '../../constants/links';
import { Layout } from '../../components/layout/index';
import { BurgerRenderer } from '../../components/header/burger-renderer';
import { ChildMarkdownRemark, Image } from '../../types/basicTypes';
import { MEDIA_QUERY, COLORS } from '../../commonStyles';

const Root = styled.div({
  margin: 'auto',
});

const Container = styled.div({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '480px',
  position: 'relative',
  [MEDIA_QUERY.TABLET]: {
    width: 'auto',
    display: 'block',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: 'auto',
    display: 'block',
  },
});

const InfoContainer = styled.div({
  width: '480px',
  [MEDIA_QUERY.TABLET]: {
    margin: '0 auto',
    width: '448px',
    paddingTop: '28px',
  },
  [MEDIA_QUERY.MOBILE]: {
    margin: '0 15px',
    width: 'auto',
    paddingTop: '35px',
  },
});

const Title = styled.div({
  color: COLORS.BLACK,
  fontSize: '72px',
  fontWeight: 'bold',
  lineHeight: 1,
  width: '100%',
  paddingBottom: '42px',
  [MEDIA_QUERY.TABLET]: {
    fontSize: '44px',
    lineHeight: 1.23,
    paddingBottom: '2px',
  },
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '36px',
    lineHeight: 1.22,
    paddingBottom: '0px',
  },
});

const Info = styled.div({
  color: COLORS.DARK_GREY,
  fontSize: '18px',
  lineHeight: 1.56,
  width: '100%',
  paddingBottom: '47px',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '16px',
    lineHeight: 1.5,
  },
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const Rectangle = styled.span({
  display: 'block',
  width: '480px',
  border: 'solid 2px #000000',
  margin: '0 auto',
  textAlign: 'center',
  verticalAlign: 'center',
  [MEDIA_QUERY.TABLET]: {
    width: '448px',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: '240px',
  },
});

const ButtonTitle = styled.span({
  display: 'block',
  padding: '12px',
  color: COLORS.BLACK,
  fontFamily: 'Arial',
  fontSize: '24px',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textDecoration: 'none',
  textTransform: 'uppercase',
  letterSpacing: '0.1px',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '16px',
  },
});

const ImgContainer = styled.div({
  paddingTop: '48px',
  width: '480px',
  [MEDIA_QUERY.TABLET]: {
    margin: '0 auto',
    width: '448px',
    paddingTop: '57px',
  },
  [MEDIA_QUERY.MOBILE]: {
    margin: '0 15px',
    width: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
  },
});

const ErrorImage = styled.img({
  width: '480px',
  height: '480px',
  [MEDIA_QUERY.TABLET]: {
    display: 'none',
  },
  [MEDIA_QUERY.MOBILE]: {
    display: 'none',
  },
});

const ErrorImageTablet = styled.img({
  width: '448px',
  height: '448px',
  [MEDIA_QUERY.DESKTOP]: {
    display: 'none',
  },
  [MEDIA_QUERY.MOBILE]: {
    display: 'none',
  },
});

const ErrorImageMobile = styled.img({
  width: '375px',
  height: '375px',
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    display: 'none',
  },
});

const notFoundData = (language: string) => {
  const { allContentfulError404Page } = useStaticQuery(graphql`
    query NotFoundQuery {
      allContentfulError404Page {
        nodes {
          node_locale
          title
          bringMeBackCaption
          info {
            childMarkdownRemark {
              html
            }
            info
          }
          picture {
            file {
              url
            }
          }
        }
      }
    }
  `);
  return allContentfulError404Page.nodes.find(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};

type Props = {
  language: string;
};

type Info = {
  info: string;
  childMarkdownRemark: ChildMarkdownRemark;
};

type ErrorPage = {
  title: string;
  bringMeBackCaption: string;
  info: Info;
  picture: Image;
};

const NotFound: React.FC<Props> = ({ language }) => {
  const { title, bringMeBackCaption, info, picture }: ErrorPage = notFoundData(
    language
  );

  return (
    <Layout
      title={title}
      language={language}
      hideFooter
      hideFooterBox
      headerLeftRenderer={BurgerRenderer}
    >
      <Root>
        <Container>
          <InfoContainer>
            <Title>{title}</Title>
            <Info>{info.info}</Info>
            <StyledLink to={`${getLink(language, 'homepage')}`}>
              <Rectangle>
                <ButtonTitle>{bringMeBackCaption}</ButtonTitle>
              </Rectangle>
            </StyledLink>
          </InfoContainer>
          <ImgContainer>
            <ErrorImage src={`${picture.file.url}?w=480&h=480`} />
            <ErrorImageTablet src={`${picture.file.url}?w=448&h=448`} />
            <ErrorImageMobile src={`${picture.file.url}?w=375&h=375`} />
          </ImgContainer>
        </Container>
      </Root>
    </Layout>
  );
};

export default NotFound;
